@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
:root {
  --cor-principal: #16947B;
  --cor-escura: #0B5F37;
  --title-color: #322153;
  --text-color: #fff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  height: 100%;
}
#root{
  height: 100%;
}
body {
  background: #f7fbfb;
  /* -webkit-font-smoothing: antialiased; */
  height: 100%;
}

body, input, button, div{
  font-family: Lato, sans-serif, sans-serif;
  outline: none;
}


form label{
  margin: 0;
}

form input{
  margin-bottom: 5px;
}
