@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
:root {
  --cor-principal: #16947B;
  --cor-escura: #0B5F37;
  --title-color: #322153;
  --text-color: #fff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  height: 100%;
}
#root{
  height: 100%;
}
body {
  background: #f7fbfb;
  /* -webkit-font-smoothing: antialiased; */
  height: 100%;
}

body, input, button, div{
  font-family: Lato, sans-serif, sans-serif;
  outline: none;
}


form label{
  margin: 0;
}

form input{
  margin-bottom: 5px;
}

.container-login{
  height: 118%;
  display: flex;

}
.column-logo{
  background-color: var(--cor-principal);
  width: 40%;
  z-index: 10;
  height: 100%;
  padding: 0;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#logo{
  margin-top: -50px;
}
.helpmaney{
  display: flex;
}
.column-logo h2{
  font-size: 45px;
  font-weight: bold;
  color: #fff;
}
.helpmaney #help{
  color: var(--cor-escura);
}
.helpmaney #maney{
  color: #fff
}
#empresas{
  margin-top: -10px;
}
#image-predios{
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.3;
  width: 40%;
  height: 100%;
  z-index: -10;
}

.column-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.column-form h1{
  font-size: 60px;
  font-weight: bold;
  color: var(--cor-principal);
}

.column-form .acesse{
  display: flex;
  width: 60%;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.column-form .acesse hr{
  border-color: var(--cor-principal);
  width: 8%;
}
.column-form .acesse h4{
  font-size: 16px;
  margin-right: 15px;
  margin-left: 15px;
  color: var(--cor-principal);
}

.column-form form{
  width: 45%;
  margin-top: 20px;
}
.column-form form .input-group{
  border: 1px solid var(--cor-principal);
  padding: 5px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.column-form form .input-group input{
  border: none;
  background: none;
  margin-left: 3%;
  width: 85%;
}
.column-form form .input-group img{
  height: 20px;
}
.column-form form button[type=submit]{
  border: none;
  background-color: var(--cor-principal);
  width: 100%;
  height: 40px;
  font-size: 22px;
  color: #fff;
  border-radius: 5px;
  outline: none;
}
.column-form form button[type=submit]:hover{
  background-color: #18a78a;
}
.column-form form p{
  color: red;
  text-align: center;
  height: 10px;
  transition-duration: 0.5s;
}
#esquece{
  margin-top: 3px;
  border: none;
  background: none;
  color: var(--cor-principal);
  outline: none;
}

#tabela-pagamentos button.refresh {
  width: 100%;
}
#tabela-pagamentos button {
  border: none;
  background: none;
  outline: none;
}
#tabela-pagamentos button.refresh img{
  height: 30px;
}

#tabela-pagamentos thead th{
  padding: 0;
}

#tabela-pagamentos tbody td{
  text-align: center;
}

#tabela-pagamentos thead tr th #btn-th{
  width: 100%;
  text-align: center;
  background-color: var(--cor-escura);
  color: #fff;
}
#tabela-pagamentos thead tr th #btn-th:hover{
  background-color: #0F7444;
}

#select-status{
  border: 1px solid #ccc;
  outline: none;
}

#sucesso {
  color: blue;
  background-color: blue;
}

.header{
  background-color: #F0F0F0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 15;
  position: absolute;
}

.card-logged{
  background: none;
  border: none;
  z-index: 99;
}

#card-hea{
  display: flex;
  align-items: center;
  background-color: #F0F0F0;
  border: none;
}
.texts{
  padding: 0;
  margin-left: 15px;
}
.texts p{
  margin: -3px;
  min-width: 150px;
}
.texts hr{
  margin: 3px 0;
}

#card-hea button[type='button']{
  background: none;
  border: none;
  outline: none;
  position: absolute;
  right: 19px;
  top: 35px;
}

div.card {
  border: none;
}

#card-body{
  position: absolute;
  top: 85px;
  right: 2%;
  width: 200px;
  background: #F0F0F0;
  padding: 15px;
  display: none;
  transition-duration: 0.2s;
  box-shadow: 1px 2px 5px rgb(95, 93, 93);
  z-index: 99;
}
#card-body.on{
  display: block;
}

#card-body button.option{
  border: none;
  background: none;
  outline: none;
  align-self: flex-start;
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 3px;
}
#card-body button.option:hover{
  background: #FFF;
}
#card-body button.option strong{
  margin-left: 5px;
}

nav.menu-principal{
  background-color: var(--cor-principal);
  width: 60px;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition-duration: 0.3s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  padding-top: 80px;
}

nav.menu-principal.hover{
  width: 230px;
  box-shadow: 1px 0px 2px #000;
}
nav.menu-principal.hover .menu-option{
  width: 100%;
  border-radius: 5px;
}
nav.menu-principal.hover .menu-option:hover{
  background-color: #4FC0AA;
}
nav.menu-principal.hover .menu-option button a{
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
}
nav.menu-principal.hover .menu-option button p{
  display: block;
}
.menu-option {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.menu-option button{
  border: none;
  background: none;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
}

.menu-option button p{
  color: #FFF;
  margin: 0;
  margin-left: 15px;
  font-weight: bold;
  font-size: 18px;
  display: none;
  white-space: nowrap;
}

#cadastro-img{
  height: 45px;
  margin-left: -5px;
}

.menu-option img {
  height: 43px;
  margin-left: -5px;
}

.loading{
  width: 40%;
  background: rgba(0, 0, 0, 0.376);
  position: absolute;
  top: 44%;
  height: 220px;
  display: none;
  border-radius: 5px;
}

.loading.on{
  display: block;
}


#form-pagamentos input{
  border: 1px solid #ccc;
  padding-left: 5px;
  margin: 0;
}

#form-pagamentos button[type='submit']{
  border: none;
  background: none;
  outline: none;
}

#form-pagamentos label{
  margin: 0;
}

#pagination {
  border: none;
  background: none;
  outline: none;
}
#pagination img{
  height: 50px;
}

#row-off{
  display: none;
}

#row-on{
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-left: 25px;
  margin-bottom: 30px;
}

#nothing {
  font-size: 20px;
  margin-top: 15px;
}


#container-empresas label{
  margin: 0;
}
#box-empresas{
  padding: 20px;
}

#box-empresas h2{
  color: var(--cor-escura);
  font-weight: bold;
  margin: 0;
}

#box-empresas p{
  color: var(--cor-principal);
  margin: 0;
}

#box-empresas hr{
  margin: 0;
}

#transacoes-pendentes{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#btn-search{
  background: none;
  border: none;
  outline: none;
}

div.amountTransactions {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;  
  margin-bottom: -20px;    
}

p.amountTransactions {    
  font-size: 15px;
  margin-right: 10px;
}



.table-transaction thead tr th{
  padding: 0;
}
.table-transaction thead tr th button{
  border: none;
  background: none;
  width: 100%;
  outline: none;
  background-color: var(--cor-escura);
  color: #fff;
}

#container-checkbox{
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0;
}

.table-transaction thead tr th button:hover{
  background: #0F7444;
}

.table-transaction tbody tr td div{
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

#td-checkbox{
  padding: 0;
  vertical-align: inherit;
}

#recibo{
  background: none;
  border: none;
  outline: none;
  width: 100%;
}

#pago-nao-pago img{
  height: 20px;
}

