.table-transaction thead tr th{
  padding: 0;
}
.table-transaction thead tr th button{
  border: none;
  background: none;
  width: 100%;
  outline: none;
  background-color: var(--cor-escura);
  color: #fff;
}

#container-checkbox{
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0;
}

.table-transaction thead tr th button:hover{
  background: #0F7444;
}

.table-transaction tbody tr td div{
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

#td-checkbox{
  padding: 0;
  vertical-align: inherit;
}

#recibo{
  background: none;
  border: none;
  outline: none;
  width: 100%;
}

#pago-nao-pago img{
  height: 20px;
}
